const translation = {
  分析: '分析',
  所属经理: '所屬經理',
  投资策略: '投資策略',
  目标: '目標',
  状态: '狀態',
  建立日期: '建立日期',
  最近分析: '最近分析',
  风险概况: '風險概況',
  选择客户: '選擇客戶',
  刚刚: '剛剛',
  chat: {
    思考中: '思考中',
    思考秒: '思考 {{number}} 秒',
    推荐问: '\n推薦問:',
    消息异常: '消息異常',
  },
  index: {
    title: '資產變化',
    亏损客户数: '虧損賬戶',
    盈利客户数: '盈利賬戶',
    全部: '全部',
    亏损: '虧損',
    盈利: '盈利',
  },
  menus: {
    首页: '首頁',
    文档: '文檔',
    客户: '客戶',
    指导: '指導',
  },
  login: {
    选择角色: '請選擇一個角色，你是',
    登录描述: '歡迎使用我們的理財助手-與我們一起開啟您的財富管理之旅，體驗智能理財的魅力！',
    请输入用户名: '請輸入用戶名',
    请输入邮箱: '請輸入郵箱',
    获取验证码: '獲取驗證碼',
    请输入邮箱验证码: '請輸入郵箱驗證碼',
    请确认密码: '請確認密碼',
    FirstName: '性',
    LastName: '名',
    请输入手机号: '請輸入手機號',
    输入邮箱: '輸入您的郵箱',
    输入密码: '輸入您的密碼',
    请输入密码: '請輸入密碼',
    账号密码不正确: '郵箱或密碼不正確',
    注册账号: '註冊賬戶',
    登录: '登錄',
    注册: '註冊',
    不能为空: '必填',
    密码规则: '密碼必須包含至少8個字符，包含數字、字母和特殊字符',
    两次输入的密码不一致: '兩次輸入的密碼不一致',
    邮箱格式不正确: '郵箱格式不正確',
    注册失败: '註冊失敗',
    注册成功: "註冊成功",
    发送验证码失败: '發送驗證碼時出現錯誤，請稍後再試',
    注册失败1: "用戶名至少要 3 個字符長",
    注册失败2: "郵箱已存在",
    注册失败3: "用戶名已存在",
    注册失败4: "電話號碼已存在",
    注册失败5: "電話號碼必須是數字",
    注册失败6: "驗證碼錯誤",
  },
  userDetailTable: {
    投资类别: '投資類別',
    特定投资或证券的名称: '特定投資或證券的名稱',
    公开交易证券的股票代码或符号: '公開交易證券的股票代碼或符號',
    客户持有的股份或单位数量: '客戶持有的股份或單位數量',
    最初购买时的价格: '最初購買時的價格',
    当前市场价格: '當前市場價格',
    总价值: '總價值',
    原始成本: '原始成本',
    市场价值与成本基础之间的差额: '市場價值與成本基礎之間的差額',
    年收益率: '年收益率',
    投资收入: '投資收入',
    总资产分配给此资产的比例: '總資產分配給此資產的比例',
    风险水平: '風險水平',
    更新投资组合数据的日期: '更新投資組合數據的日期',
  },
  upload: {
    文件类型不支持: '文件類型不支持',
    文件太大: '文件過大，不能超過 {{size}}MB',
    上传成功: '上傳成功',
    上传限制个数: '批量上傳限制 {{number}} 個文件',
    拖拽文件至此或者: '拖拽文件到此，或',
    下载模板: '下載模板',
    下载用户数据: '下載用戶數據',
    下载理财数据: '下載理財數據',
    上传用户或理财信息: '上傳賬戶或財富數據',
    上传理财信息: '上傳理財信息',
    选择文件: '選擇文件',
    支持文件: '支持 {{exp}}，每個文件不能超過 {{size}}MB'
  },
  api: {
    success: '成功',
    actionSuccess: '操作成功',
    saved: '已保存',
    create: '已創建',
    remove: '已移除',
  },
}

export default translation
