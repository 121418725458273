
export const formatCurrency = function (amount: number | string, dot = 2) {
  amount = amount + ''
  // 确保输入为数字，并四舍五入到两位小数
  const roundedAmount = parseFloat(amount).toFixed(dot);

  // 使用正则表达式添加千分位分隔符
  const formattedAmount = roundedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return formattedAmount;
}
export function randomString(len) {
  len = len || 32
  const $chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const maxPos = $chars.length
  let pwd = ''
  for (let i = 0; i < len; i++) {
    // 0~32的整数
    pwd += $chars.charAt(Math.floor(Math.random() * (maxPos + 1)))
  }
  return pwd
}

export const formatDate = (tmp: string | number | Date, format) => {
  let fmt = format || 'yyyy-MM-dd hh:mm:ss'
  let date = null
  if (!tmp) {
    return ''
  }
  if (tmp instanceof Date) {
    date = tmp
  } else if (typeof tmp === 'string' || typeof tmp === 'number') {
    date = new Date(tmp)
  } else {
    return ''
  }
  if (date === 'Invalid Date') {
    const aaa = (tmp as string).replace(/-/g, '/')
    date = new Date(aaa)
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (`${date.getFullYear()}`).substr(4 - RegExp.$1.length),
    )
  }
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  }
  Object.keys(o).forEach((k) => {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k] : (`00${o[k]}`).substr((`${o[k]}`).length),
      )
    }
  })
  return fmt
}

async function decodeBase64AndDecompress(base64String: string) {
  const binaryString = atob(base64String)
  const compressedUint8Array = Uint8Array.from(binaryString, char => char.charCodeAt(0))
  const decompressedStream = new Response(compressedUint8Array).body.pipeThrough(new DecompressionStream('gzip'))
  const decompressedArrayBuffer = await new Response(decompressedStream).arrayBuffer()
  return new TextDecoder().decode(decompressedArrayBuffer)
}

export function getProcessedInputsFromUrlParams(): Record<string, any> {
  console.log('21234', window.location.search);
  const urlParams = new URLSearchParams(window.location.search)
  const inputs: Record<string, any> = {}
  urlParams.forEach((value, key) => {
    inputs[key] = decodeURIComponent(value)
  })
  return inputs
}


export const numberWithCommas = (x: any, decimalNum: number = 2) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (parts[1] && decimalNum) {
    parts[1] = parts[1].slice(0, decimalNum);
  }
  return parts.join(".");
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};
