const translation = {
  分析: '分析',
  所属经理: '所属经理',
  投资策略: '投资策略',
  目标: '目标',
  状态: '状态',
  建立日期: '建立日期',
  最近分析: '最近分析',
  风险概况: '风险概况',
  选择客户: '选择客户',
  刚刚: '刚刚',
  chat: {
    思考中: '思考中',
    思考秒: '思考 {{number}} 秒',
    推荐问: '\n推荐问:',
    消息异常: '消息异常',
  },
  index: {
    title: '资产变化',
    亏损客户数: '亏损账户',
    盈利客户数: '盈利账户',
    全部: '全部',
    亏损: '亏损',
    盈利: '盈利',
  },
  menus: {
    首页: '首页',
    文档: '文档',
    客户: '客户',
    指导: '指导',
  },
  login: {
    选择角色: '请选择一个角色，你是',
    登录描述: '欢迎使用智能电话销售助手，助您轻松管理客户，提升销售业绩！',
    请输入用户名: '请输入用户名',
    请输入邮箱: '请输入邮箱',
    获取验证码: '获取验证码',
    请输入邮箱验证码: '请输入邮箱验证码',
    请确认密码: '请确认密码',
    FirstName: '性',
    LastName: '名',
    请输入手机号: '请输入手机号',
    输入邮箱: '输入您的邮箱',
    输入密码: '输入您的密码',
    请输入密码: '请输入密码',
    账号密码不正确: '邮箱或密码不正确',
    注册账号: '注册账号',
    登录: '登录',
    注册: '注册',
    不能为空: 'Required',
    密码规则: '密码必须包含至少8个字符，包含数字、字母和特殊字符',
    两次输入的密码不一致: '两次输入的密码不一致',
    邮箱格式不正确: '邮箱格式不正确',
    注册失败: '注册失败',
    注册成功: "注册成功",
    发送验证码失败: '发送验证码时出现错误，请稍后再试',
    注册失败1: "用户名至少要 3 个字符长",
    注册失败2: "邮箱已存在",
    注册失败3: "用户名已存在",
    注册失败4: "电话号码已存在",
    注册失败5: "电话号码必须是数字",
    注册失败6: "验证码错误",
  },
  userDetailTable: {
    投资类别: '投资类别',
    特定投资或证券的名称: '特定投资或证券的名称',
    公开交易证券的股票代码或符号: '公开交易证券的股票代码或符号',
    客户持有的股份或单位数量: '客户持有的股份或单位数量',
    最初购买时的价格: '最初购买时的价格',
    当前市场价格: '当前市场价格',
    总价值: '总价值',
    原始成本: '原始成本',
    市场价值与成本基础之间的差额: '市场价值与成本基础之间的差额',
    年收益率: '年收益率',
    投资收入: '投资收入',
    总资产分配给此资产的比例: '总资产分配给此资产的比例',
    风险水平: '风险水平',
    更新投资组合数据的日期: '更新投资组合数据的日期',
  },
  upload: {
    文件类型不支持: '文件类型不支持',
    文件太大: '文件过大，不能超过 {{size}}MB',
    上传成功: '上传成功',
    上传限制个数: '批量上传限制 {{number}} 个文件',
    拖拽文件至此或者: '拖拽文件到此，或',
    下载模板: '下载模板',
    下载用户数据: '下载用户数据',
    下载理财数据: '下载理财数据',
    上传用户或理财信息: '上传账户或财富数据',
    上传理财信息: '上传理财信息',
    选择文件: '选择文件',
    支持文件: '支持 {{exp}}，每个文件不能超过 {{size}}MB'
  },
  api: {
    success: '成功',
    actionSuccess: '操作成功',
    saved: '已保存',
    create: '已创建',
    remove: '已移除',
  },
}

export default translation
