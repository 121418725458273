import { createContext, useContext, useContextSelector } from 'use-context-selector';
import React from 'react';
import { getconversationsApi, getMessagesApi, deleteconversationApi } from '@/service'
import { formatDate, getProcessedInputsFromUrlParams } from '@/lib/utils'
import {useAuth} from '@/context/authContext'

const MenuContext = createContext<{ 
  isSessionCollapse: boolean; setIsSessionCollapse: (isSessionCollapse: boolean) => void;
  conversationId: string; setConversationId: (conversationId: string) => void;
  changeConversationId: (conversationId: string, isFresh?:boolean) => void;
  chats: any;
  chatList: Chat[];
  setChatList: (chatList: any) => void;
  deleteconversation: (conversationId: string, params?: any) => void;
  messagesSuggested: any[];
  setMessagesSuggested: (messagesSuggested: any) => void;
 }>({
  isSessionCollapse: true,
  setIsSessionCollapse: () => { },
  conversationId: '',
  setConversationId: ()=>{},
  changeConversationId: ()=>{},
  chats: [],
  chatList: [],
  setChatList: () => { },
  deleteconversation: () => { },
  messagesSuggested: [],
  setMessagesSuggested: () => { },
});


interface MenuContextProviderProps {
  children: React.ReactNode;
}

export const SessionMenuContextProvider: React.FC<MenuContextProviderProps> = ({ children }) => {
  const [isSessionCollapse, setIsSessionCollapse] = React.useState(true);
  const [conversationId, setConversationId] = React.useState('');
  const [messagesSuggested,setMessagesSuggested] = React.useState([])
  
  const { session } = useAuth()

  let tempConversationId = ''
  const changeConversationId = (value: string, isFresh?:boolean) => {
    if (tempConversationId && value === tempConversationId) {
      return
    } else if (value === '') {
      setMessagesSuggested([])
      setConversationId('')
      setChatList([])
      setIsSessionCollapse(true)
      if (isFresh) {
        getconversations()
      }
      return
    }
    setMessagesSuggested([])
    console.log('123123123', value, isFresh);
    tempConversationId = value
    setConversationId(value)
    if (isFresh) {
      // 获取会话列表
      getconversations()
    } else {
      setChatList([]) 
      // 获取历史消息
      const { userId } = getProcessedInputsFromUrlParams()
      getMessagesApi({
        conversation_id: value,
        user: userId,
      }).then((res:any) => {
        console.log('12333', res);
        if (res.data) {
        setChatList(res.data.map(item => {
          return[{
            role: 'user',
            id: item.id,
            date: item.created_at,
            status: 'success',
            data: [{
              type: 'text',
              data: item.query
            }]
          },{
            role: 'robot',
            id: item.id+'robot',
            date: item.updated_at || item.created_at,
            status: 'success',
            data: [{
              type: 'text',
              data: item.answer
            }]
          }]
        }).flat())
        }
      })
    }
  }



  // 会话列表
  const [chats, setChats] = React.useState([
  //   {
  //         "id": "10753fdb-2cd5-4383-844e-8ad09732aa68",
  //         "name": "Sharing a number",
  //         "inputs": {
  //             "client_id": "387b85d4-8852-4d83-aa9c-9e3661cdd36f"
  //         },
  //         "status": "normal",
  //         "introduction": "Welcome! As your financial management assistant, I will help you retrieve and analyze your portfolio information. Please provide your client ID, and I will present you with detailed asset data, historical performance, and risk assessment. Based on the analysis results, I will offer optimization suggestions and specific implementation plans. Feel free to let me know your needs at any time!",
  //         "created_at": 1726307535
  // }
  ])
  // 历史消息
  const [chatList, setChatList] = React.useState<Chat[]>([]);

  function getconversations() {
    getconversationsApi({
      user: session?.user?.id,
      limit: 20,
    }).then((res:any) => {
     // console.log('1111111111111', res);
      if (res.data) {
        setChats(res.data)



      }
      
    })
  }

  function deleteconversation(conversationValue: string) {
    const { userId } = getProcessedInputsFromUrlParams()
    deleteconversationApi(conversationValue, {
      user: userId
    }).then((res:any) => {
      if (res.status === 200) {
        if (conversationId === conversationValue) {
          changeConversationId('', true)
        }
      }
    })
  }

  React.useEffect(() => {
    if (session) {
      if (session?.user) {
        getconversations()
      }
    }
  }, [session])

  return (
    <MenuContext.Provider value={{
      isSessionCollapse, setIsSessionCollapse, conversationId, setConversationId, changeConversationId, chats, chatList, setChatList, deleteconversation,
      messagesSuggested, setMessagesSuggested
     }}>
    { children }
    </MenuContext.Provider>
  )
};

export const useSessionMenuContext = () => useContext(MenuContext)

export default MenuContext